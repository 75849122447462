import React from 'react';

import styles from './TextCard.module.scss';

const TextCard = ({ title, text }) => (
	<div className={styles.textCard}>
		<div className={styles.title}>{title}</div>
		<div className={styles.text}>{text}</div>
	</div>
);

export default TextCard;
