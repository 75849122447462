import React from 'react';
import styles from './Hero.module.scss';

import { Margins, Container } from '..';

const Hero = ({ title, text, image }) => (
	<Container left={236}>
		<div className={styles.hero}>
			<figure className={styles.hero__figure}>
				<img
					src={image}
					loading="lazy"
					height="1080"
					width="1920"
					alt={title}
					className={styles.hero__image}
				/>
			</figure>
			<div className={styles.hero__content}>
				<div className={styles.hero__titleContainer}>
					<h1 className={styles.hero__title}>{title}</h1>
				</div>
				<Margins className={styles.hero__description}>
					{text.map((paragraph, index) => (
						<p key={index} className="f-large color-black-light">
							{paragraph.description}
						</p>
					))}
				</Margins>
			</div>
		</div>
	</Container>
);

export default Hero;
