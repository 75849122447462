import React, { useState, useRef } from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';

import { useOutsideClick } from '../../utils/hooks';
import { getCurrentPagesBySlug } from '../../utils/helpers';

import styles from './LanguageMenu.module.scss';

const getLang = (locale) =>
	({
		et: 'Est',
		en: 'Eng',
		lv: 'Lav',
		lt: 'Lit',
		pl: 'Pol',
		de: 'Ger',
	}[locale]);

const LanguageMenu = ({ slug, className, locale, pages }) => {
	const [menuOpen, setMenuOpen] = useState(false);
	const wrapperRef = useRef(null);
	useOutsideClick(wrapperRef, () => setMenuOpen(false));

	const languages = getCurrentPagesBySlug(slug, pages);

	return (
		<div
			ref={wrapperRef}
			className={cx(styles.languageMenu, className, {
				[styles.open]: menuOpen,
			})}
		>
			<button
				onClick={() => setMenuOpen(!menuOpen)}
				className={styles.button}
			>
				<span>{getLang(locale)}</span>
				<div className={styles.triangle} />
			</button>
			{menuOpen && (
				<menu className={styles.menu}>
					{languages
						.filter(({ lang: l }) => l !== locale)
						.map(({ lang, path }, i) => (
							<Link
								to={path.split('homepage/')[0]}
								className={styles.menuItem}
								key={i}
							>
								{getLang(lang)}
							</Link>
						))}
				</menu>
			)}
		</div>
	);
};

export default LanguageMenu;
