import React from 'react';
import cn from 'classnames';
import styles from './Container.module.scss';

const Container = ({
	className,
	children,
	left = 0,
	right = 0,
	innerRelative,
}) => {
	const containerClass = styles[`container${left}${right}`];
	const classes = cn(
		styles.container,
		{
			[containerClass]: containerClass,
			[styles.containerInnerRelative]: innerRelative,
		},
		className
	);

	return (
		<div className={classes}>
			<div className={styles.container__outer}>
				<div className={styles.container__inner}>{children}</div>
			</div>
		</div>
	);
};

export default Container;
