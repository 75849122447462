import React from 'react';
import cn from 'classnames';
import styles from './Background.module.scss';

const Background = ({ children, light, floated, slideUp, wider }) => {
	const classes = cn(styles.background, {
		[styles.backgroundLight]: light,
		[styles.backgroundFloated]: floated,
		[styles.backgroundSlideUp]: slideUp,
		[styles.backgroundWider]: wider,
	});

	return <div className={classes}>{children}</div>;
};

export default Background;
