import React from 'react';
import PropTypes from 'prop-types';

import styles from './SnusBoxList.module.scss';
import { Grid } from '..';

const SnusBoxList = ({ data, colWidth, align }) => {
	return (
		<Grid align={align}>
			{data.map((row, i) => (
				<Grid.Col key={i} width={colWidth}>
					<div className={styles.snusBoxList__item}>
						<img src={row.image} loading="lazy" alt={row.title} />
						<p className="f-bold f-large">{row.title}</p>
						<p>{row.subtitle}</p>
					</div>
				</Grid.Col>
			))}
		</Grid>
	);
};

SnusBoxList.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object).isRequired,
	colWidth: PropTypes.string.isRequired, // Desktop cols, mobile always 2
};

export default SnusBoxList;
