import React from 'react';
import cn from 'classnames';
import styles from './Burger.module.scss';

const Burger = ({ visible, onClick, className }) => {
	const classes = cn(styles.burger, className, {
		[styles.visible]: visible,
	});

	return (
		<button onClick={onClick} className={classes} aria-label="Menu">
			<span className={styles.burger__content} />
		</button>
	);
};

export default Burger;
