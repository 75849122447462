import React from 'react';
import cn from 'classnames';
import styles from './ButtonCircle.module.scss';

const ButtonCircle = ({ children, className, ...props }) => (
	<button className={cn(styles.button, className)} {...props}>
		{children}
	</button>
);

export default ButtonCircle;
