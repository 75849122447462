import React from 'react';
import { Link } from 'gatsby';

import styles from './MobileMenu.module.scss';
import { SocialButton, LanguageMenu } from '../';
import Grid from '../grid/Grid.js';

const MobileMenu = ({ slug, pages, locale }) => {
	const socials = pages[locale]?.find((page) => page.key === 'home')?.acf
		?.footer_socials;

	return (
		<div className={styles.mobileMenu__container}>
			<ul>
				{pages[locale].map(({ acf: { nav_title }, path }, index) => (
					<li key={index}>
						<Link
							className={styles.mobileMenu__navLink}
							to={
								path.includes('homepage') ? `/${locale}/` : path
							}
							key={index}
							activeClassName={styles.active}
						>
							{nav_title || 'title-missing'}
						</Link>
					</li>
				))}
			</ul>
			<div className={styles.mobileMenu__lang}>
				<LanguageMenu slug={slug} locale={locale} pages={pages} />
			</div>
			{socials && socials.length > 0 && (
				<Grid className={styles.mobileMenu__social}>
					{socials.map(({ link, key, label }, index) => (
						<Grid.Col width="xs" key={index}>
							<SocialButton
								link={link}
								icon={key}
								alt={label}
								small
							/>
						</Grid.Col>
					))}
				</Grid>
			)}
		</div>
	);
};

export default MobileMenu;
