import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Spacer.module.scss';

const Spacer = ({ mobile, desktop }) => (
	<div
		className={cn({
			[styles[`spacerMobile${mobile}`]]: typeof mobile === 'number',
			[styles[`spacerDesktop${desktop}`]]: typeof desktop === 'number',
		})}
	></div>
);

Spacer.propTypes = {
	mobile: PropTypes.oneOf([10, 20, 30, 40, 50, 60, 65, 70]),
	desktop: PropTypes.oneOf([0, 30, 40, 60, 70, 80, 100, 120, 155, 180, 200]),
};

export default Spacer;
