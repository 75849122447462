import React from 'react';
import classNames from 'classnames';

import styles from './Input.module.scss';

const Input = ({
	field,
	form: { touched, errors },
	label,
	type = 'text',
	id,
	className,
	isHoneyPot,
	...props
}) => {
	const renderInput = () =>
		type === 'textarea' ? (
			<textarea
				className={styles.input__input}
				placeholder={label}
				id={id}
				rows="5"
				{...field}
				{...props}
			/>
		) : (
			<input
				className={styles.input__input}
				placeholder={label}
				type={type}
				id={id}
				tabIndex={isHoneyPot ? -1 : 0}
				{...field}
				{...props}
			/>
		);

	const hasError = touched[field.name] && errors[field.name];

	return (
		<div
			className={classNames(
				styles.input,
				{ [styles.inputError]: hasError },
				{ [className]: !!className },
				{ [styles.isHoneyPot]: isHoneyPot },
				{ [styles.filled]: !!field.value }
			)}
		>
			<label htmlFor={id} className={styles.input__label}>
				{label}
			</label>
			{renderInput()}
		</div>
	);
};

export default Input;
