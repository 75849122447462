import React from 'react';
import classNames from 'classnames';

const Margins = ({ children, className }) => (
	<div className={classNames('margins', { [className]: !!className })}>
		{children}
	</div>
);

export default Margins;
