import React from 'react';
import cn from 'classnames';
import styles from './LogoList.module.scss';

const LogoList = ({ logos = [], className }) => (
	<div className={cn(styles.logoList, className)}>
		{logos.map((logo, index) => (
			<div key={index} className={styles.logoList__item}>
				<img
					className={styles.logoList__img}
					src={logo.image}
					alt={logo.alt}
					loading="lazy"
				/>
			</div>
		))}
	</div>
);

export default LogoList;
