import React from 'react';
import cx from 'classnames';
import { Link } from 'gatsby';

import styles from './CircleButton.module.scss';

const CircleButton = ({ link, text, small, hoverColor, color, className }) => {
	const classNames = cx(
		styles.circleButton,
		{ [styles.small]: small },
		{
			[styles.hoverWhite]: hoverColor === 'white',
		},
		{
			[styles.white]: color === 'white',
		},
		className
	);
	return (
		<Link className={classNames} to={link}>
			<span>{text}</span>
		</Link>
	);
};

export default CircleButton;
