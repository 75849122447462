import React from 'react';
import cx from 'classnames';

import styles from './Number.module.scss';

const Number = ({ number, className, white, lightGray }) => {
	return (
		<div
			className={cx(
				styles.number,
				className,
				white && styles.white,
				lightGray && styles.lightGray
			)}
		>
			{number.toString().length === 1 ? `0${number}` : number}
		</div>
	);
};

export default Number;
