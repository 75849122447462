import React from 'react';
import cn from 'classnames';
import styles from './SocialButton.module.scss';

const icons = {
	fb: (
		<svg
			width="54px"
			height="54px"
			viewBox="0 0 54 54"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<g
				id="Symbols"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="Components_jalus"
					transform="translate(-1103.000000, -105.000000)"
					fill="#DAC2B2"
				>
					<path
						d="M1130,105 C1144.91188,105 1157,117.08925 1157,131.998875 C1157,146.91075 1144.91188,159 1130,159 C1115.08812,159 1103,146.91075 1103,131.998875 C1103,117.08925 1115.08812,105 1130,105 Z M1133.05525,123.140125 L1136.53488,123.140125 L1136.53488,118 L1132.44438,118 L1132.44438,118.02025 C1127.491,118.194625 1126.47287,120.98125 1126.384,123.907375 L1126.375,123.907375 L1126.375,126.472375 L1123,126.472375 L1123,131.50675 L1126.375,131.50675 L1126.375,145 L1131.46113,145 L1131.46113,131.50675 L1135.627,131.50675 L1136.43025,126.472375 L1131.46113,126.472375 L1131.46113,124.92325 C1131.46113,123.936625 1132.11812,123.140125 1133.05525,123.140125 Z"
						id="facebook"
					></path>
				</g>
			</g>
		</svg>
	),
	in: (
		<svg
			width="54px"
			height="54px"
			viewBox="0 0 54 54"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<g
				id="Symbols"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="Components_jalus"
					transform="translate(-1190.000000, -105.000000)"
					fill="#DAC2B2"
				>
					<path
						d="M1217,105 C1231.913,105 1244,117.08925 1244,131.998875 C1244,146.91075 1231.913,159 1217,159 C1202.08925,159 1190,146.91075 1190,131.998875 C1190,117.08925 1202.08925,105 1217,105 Z M1225.28529,145.502812 C1228.16304,145.502812 1230.50416,143.163937 1230.50416,140.283937 L1230.50416,129.228562 L1230.50416,123.714937 C1230.50416,120.840562 1228.16304,118.497187 1225.28529,118.497187 L1208.71629,118.497187 C1205.83741,118.497187 1203.49629,120.840562 1203.49629,123.714937 L1203.49629,129.228562 L1203.49629,140.283937 C1203.49629,143.163937 1205.83741,145.502812 1208.71629,145.502812 L1225.28529,145.502812 Z M1216.99966,128.623312 C1218.86491,128.623312 1220.37579,130.134187 1220.37579,131.999437 C1220.37579,133.861312 1218.86491,135.375562 1216.99966,135.375562 C1215.13666,135.375562 1213.62579,133.861312 1213.62579,131.999437 C1213.62579,130.134187 1215.13666,128.623312 1216.99966,128.623312 Z M1216.99966,138.749437 C1220.72791,138.749437 1223.75079,135.731062 1223.75079,131.999437 C1223.75079,130.763062 1223.39416,129.620062 1222.81366,128.623312 L1227.12241,128.623312 L1227.12241,139.532437 C1227.12241,140.961187 1225.96141,142.124437 1224.53491,142.124437 L1209.41941,142.124437 C1207.99179,142.124437 1206.83079,140.961187 1206.83079,139.532437 L1206.83079,128.623312 L1211.18679,128.623312 C1210.60741,129.620062 1210.25079,130.763062 1210.25079,131.999437 C1210.25079,135.731062 1213.27254,138.749437 1216.99966,138.749437 Z M1227.14379,122.317687 L1227.14379,125.257312 L1223.74404,125.266312 L1223.73279,121.892437 L1226.69829,121.881187 L1227.14379,121.881187 L1227.14379,122.317687 Z"
						id="instagram"
					></path>
				</g>
			</g>
		</svg>
	),
};

const SocialButton = ({ link, small, icon = 'fb', label }) => (
	<a
		className={cn(styles.socialButton, {
			[styles.socialButtonSmall]: small,
		})}
		href={link}
		target="_blank"
		rel="noopener noreferrer"
		aria-label={label || link}
	>
		{icons[icon]}
	</a>
);

export default SocialButton;
