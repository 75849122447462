import React from 'react';
import cn from 'classnames';

import MapSvg from './MapSvg';
import styles from './Map.module.scss';
import { useState } from 'react';

function firstCharUppercase(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

const Map = ({ countries }) => {
	const [country, setCountry] = useState();

	const currentCountry = countries.find((c) => c.country_key === country);

	return (
		<div className={styles.map}>
			{currentCountry && (
				<React.Fragment>
					<div
						className={cn(
							styles.map__popup,
							styles[`map__popup${firstCharUppercase(country)}`]
						)}
					>
						<div>
							<h5 className="f-bold">
								{currentCountry.country_name}
							</h5>
							<h6>{currentCountry.country_label}</h6>
						</div>
					</div>
					{/* <div className={cn(styles.map__dot, styles[`map__dot${firstCharUppercase(country)}`])} />
          <div className={cn(styles.map__line, styles[`map__line${firstCharUppercase(country)}`])} /> */}
				</React.Fragment>
			)}
			<MapSvg
				onMouseEnter={(_, country) => setCountry(country)}
				onMouseLeave={() => setCountry()}
			/>
		</div>
	);
};

export default Map;
