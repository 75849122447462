import React from 'react';
import cn from 'classnames';
import styles from './Separator.module.scss';

const Separator = ({ className }) => {
	const classes = cn(styles.separator, className);

	return <hr className={classes} />;
};

export default Separator;
