import React from 'react';

import styles from './BulletListItem.module.scss';

const BulletListItem = ({ icon, text }) => {
	return (
		<li className={styles.item}>
			<div className={styles.imageWrapper}>
				<img
					src={icon}
					loading="lazy"
					alt={text}
					className={styles.image}
				/>
			</div>
			<p className={styles.text}>{text}</p>
		</li>
	);
};

export default BulletListItem;
