import React, { useState } from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';

import { Burger, MobileMenu, LanguageMenu } from '..';
import styles from './Header.module.scss';

const Header = ({
	pageContext: { currentLanguagePath, pages, locale, slug },
}) => {
	const [isOpen, setOpen] = useState(false);

	return (
		<header
			className={cn(styles.header, {
				[styles.open]: isOpen,
			})}
		>
			<div className={styles.content}>
				<h1 className={styles.logo} style={{ textTransform: 'none' }}>
					<Link to={`${currentLanguagePath}`}>
						Foxtrot UC
						<span className={styles.dot}>.</span>
					</Link>
				</h1>
				{isOpen && (
					<button
						className={styles.header__overlay}
						onClick={() => setOpen(false)}
						aria-label="Close menu"
					/>
				)}
				<div className={styles.header__mobileMenuContainer}>
					<MobileMenu
						slug={slug}
						locale={locale}
						pages={pages}
						onNavigate={() => setOpen(false)}
					/>
				</div>
				<ul className={styles.header__nav}>
					{pages[locale].map(
						({ acf: { nav_title }, path: pagePath }, index) => (
							<li key={index}>
								<Link
									className={styles.header__navLink}
									to={pagePath.split('homepage/')[0]}
									key={pagePath}
									activeClassName={styles.active}
								>
									{nav_title || 'title-missing'}
								</Link>
							</li>
						)
					)}
				</ul>
				<LanguageMenu
					className={styles.header__lang}
					slug={slug}
					locale={locale}
					pages={pages}
				/>
				<Burger
					className="hidden-desktop"
					visible={isOpen}
					onClick={() => setOpen(!isOpen)}
				/>
			</div>
		</header>
	);
};

export default Header;
