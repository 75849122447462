import React from 'react';
import cx from 'classnames';

import styles from './SectionTitle.module.scss';

export const SectionTitle = ({ children, className }) => {
	return <h1 className={cx(styles.title, className)}>{children}</h1>;
};

export default SectionTitle;
