import React from 'react';
import cn from 'classnames';

import styles from './NumberedContent.module.scss';

const NumberedContent = ({ number, title, children, indent, large, light }) => (
	<div className={cn(styles.numberedContent)}>
		<div
			className={cn(styles.numberedContent__number, {
				[styles.numberedContent__numberLight]: light,
			})}
		>
			<h3>{String(number).length > 1 ? number : `0${number}`}</h3>
		</div>
		{(title || indent) && (
			<div className={styles.numberedContent__indent}>
				{title && (
					<h2
						className={`${styles.numberedContent__title}${
							large ? ' h1' : ''
						}`}
					>
						{title}
					</h2>
				)}
				{indent && children}
			</div>
		)}
		{!indent && (
			<div className={styles.numberedContent__fullWidth}>{children}</div>
		)}

		{/* <div className={styles.numberedList__item}>
      <div className={styles.numberedList__title}>
        <h3>{ String(number).length > 1 ? number : `0${number}` }</h3>
        <h2>{ title }</h2>
      </div>
      <div className={styles.numberedList__content}>
        { children }
      </div>
    </div> */}
	</div>
);

export default NumberedContent;
