/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import { getCurrentPagesBySlug } from '../../utils/helpers';

function SEO({
	lang = 'en',
	meta = [],
	pages,
	title,
	description,
	image,
	location,
	slug,
}) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
						siteUrl
					}
				}
			}
		`
	);

	const metaTitle = title
		? `${title} | ${site.siteMetadata.title}`
		: site.siteMetadata.title;
	const metaDescription = description || site.siteMetadata.description;
	const url = location
		? `${site.siteMetadata.siteUrl}${location}`
		: site.siteMetadata.siteUrl;

	const locales = getCurrentPagesBySlug(slug, pages);

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={metaTitle}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:url`,
					content: url,
				},
				{
					property: `og:title`,
					content: metaTitle,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					property: `og:image`,
					content: image,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:title`,
					content: metaTitle,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
				{
					name: `twitter:image`,
					content: image,
				},
			].concat(meta)}
		>
			{locales.map((locale) => {
				const pagePath = locale.path.split('homepage/')[0];

				return (
					<link
						key={locale.wpml_current_locale}
						rel="alternate"
						hreflang={locale.lang}
						href={`${site.siteMetadata.siteUrl}${pagePath}`}
					/>
				);
			})}
		</Helmet>
	);
}

export default SEO;
