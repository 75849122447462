import React from 'react';
import { SkipNavLink, SkipNavContent } from '@reach/skip-nav';

import styles from './Layout.module.scss';

import '../../assets/scss/main.scss';
import { SEO, Header, Footer } from '..';

const Layout = ({ pageContext, children, ...props }) => {
	return (
		<>
			<SEO
				lang={pageContext.locale}
				location={pageContext.currentPagePath}
				pages={pageContext.pages}
				slug={pageContext.slug}
				{...props}
			/>
			<h1 className="sr-only">Foxtrot Uniform Capital</h1>
			<SkipNavLink />
			<div className={styles['layout']}>
				<Header pageContext={pageContext} />
				<SkipNavContent>
					<main>{children}</main>
				</SkipNavContent>
				<Footer pageContext={pageContext} />
			</div>
		</>
	);
};

export default Layout;
