import React from 'react';
import { Link } from 'gatsby';

import { Grid, SocialButton } from '..';

import styles from './Footer.module.scss';

const Spacer = () => <div className={styles.spacer} />;

const AddressLink = ({ value, label }) => (
	<a href={value} target="_blank" rel="noopener noreferrer">
		{label}
	</a>
);

const EmailLink = ({ value, label }) => <a href={`mailto:${value}`}>{label}</a>;

const PhoneLink = ({ value, label }) => <a href={`tel:${value}`}>{label}</a>;

const InvalidContact = () => null;

const renderContact = (
	{ contact_label, contact_type, contact_value },
	index
) => {
	let Component = InvalidContact;
	if (contact_type === 'email') {
		Component = EmailLink;
	} else if (contact_type === 'phone') {
		Component = PhoneLink;
	} else if (contact_type === 'address') {
		Component = AddressLink;
	}

	return (
		<Component key={index} value={contact_value} label={contact_label} />
	);
};

const Footer = ({ pageContext: { pages, locale } }) => {
	const footerData = pages[locale]?.find((page) => page.key === 'home')?.acf;

	return (
		<footer className={styles.footer}>
			<Grid className={styles.content}>
				<Grid.Col offset="sm-2" width="sm-20">
					<Grid>
						<Grid.Col width="sm-8">
							<h1 className={styles.logo}>
								Foxtrot UC
								<span className={styles.dot}>.</span>
							</h1>
							<div className={styles.contact}>
								{footerData.footer_contacts?.map(
									(contact, index) =>
										renderContact(contact, index)
								)}
							</div>
						</Grid.Col>
						<Grid.Col className={styles.pages} width="sm-8">
							<p className={`${styles.bold} uppercase`}>
								{footerData.footer_pages_title}
							</p>
							<div className={styles.pageList}>
								{pages[locale]?.map(
									({ acf: { nav_title }, path }, i) => (
										<Link
											key={i}
											to={
												path.includes('homepage')
													? `/${locale}/`
													: path
											}
										>
											{nav_title || 'translation-missing'}
										</Link>
									)
								)}
							</div>
						</Grid.Col>
						{footerData.footer_socials &&
							footerData.footer_socials.length > 0 && (
								<Grid.Col offset="sm-4" width="sm-4">
									<p className={`${styles.bold} uppercase`}>
										{footerData.footer_social_title}
									</p>

									<div className={styles.socialLinks}>
										{footerData.footer_socials.map(
											({ link, key, label }, index) => (
												<SocialButton
													key={index}
													link={link}
													icon={key}
													alt={label}
												/>
											)
										)}
									</div>
								</Grid.Col>
							)}
					</Grid>

					<Spacer />

					<Grid>
						<Grid.Col width="sm-12" className={styles.copyRight}>
							{footerData.footer_copyright}
						</Grid.Col>

						<Grid.Col
							className={styles.documentLinks}
							width="sm-12"
						>
							<Grid align="end-xs">
								<Grid.Col width="xs">
									<a
										className="uppercase"
										target="_blank"
										rel="noreferrer"
										href={footerData.footer_privacy_link}
									>
										{footerData.footer_privacy_label}
									</a>
								</Grid.Col>
								<Grid.Col width="xs">
									<a
										className="uppercase"
										target="_blank"
										rel="noopener noreferrer"
										href={footerData.footer_terms_link}
									>
										{footerData.footer_terms_label}
									</a>
								</Grid.Col>
							</Grid>
						</Grid.Col>
					</Grid>
				</Grid.Col>
			</Grid>
		</footer>
	);
};

export default Footer;
