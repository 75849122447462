import React from 'react';

const Image = ({ src, className, alt, background }) => {
	// return background ? (
	// 	<div
	// 		style={{
	// 			background: `${src}`,
	// 			backgroundPosition: 'center',
	// 			backgroundSize: 'cover',
	// 			backgroundRepeat: 'no-repeat',
	// 		}}
	// 	>
	// 		{src}{' '}
	// 	</div>
	// ) : (
	// 	<img src={src} alt={alt} className={className} />
	// )

	return <img src={src} alt={alt} loading="lazy" className={className} />;
};

export default Image;
