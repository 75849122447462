export const getPagePathByTitle = (title, locale, pages) => {
	if (!pages[locale] || !Array.isArray(pages[locale]))
		throw new Error(`No pages found for locale: ${locale}`);

	const page = pages[locale].find((page) => page.title === title);

	if (!page) {
		return '';
	}

	return page.path;
};

export const getCurrentPagesBySlug = (slug, pages) => {
	const allPages = Object.values(pages).flat();
	const pageInLocale = allPages.find((page) => page.slug === slug);
	const samePageIds = pageInLocale.wpml_translations.map(
		({ wordpress_id }) => wordpress_id
	);
	return [
		...allPages.filter((page) => samePageIds.includes(page.wordpress_id)),
		pageInLocale,
	];
};
