import React from 'react';
import styles from './Radio.module.scss';

const Radio = ({ label, name, value, field }) => (
	<label className={styles.radio}>
		<input
			name={name}
			className={styles.radio__input}
			type="radio"
			value={value}
			{...field}
		/>
		<span className={styles.radio__span}>{label}</span>
	</label>
);

export default Radio;
