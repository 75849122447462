import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Col from './Col';

class Grid extends Component {
	static Col = Col;

	getBEM() {
		let bem = this.props.block ? '' : 'grid';

		if (this.props['equal-height']) bem += ' grid--equalheight';

		if (this.props.reverse) {
			this.props.reverse.split(',').forEach((rev) => {
				rev = rev.trim();
				if (/(xs|sm|md|lg|xl)/.test(rev))
					bem += ` grid--reverse-${rev}`;
			});
		}

		if (this.props.align) {
			this.props.align.split(',').forEach((align) => {
				align = align.trim();
				if (
					/(top|middle|bottom|start|center|end|around|between)-(xs|sm|md|lg|xl)/.test(
						align
					)
				)
					bem += ` grid--${align}`;
			});
		}

		if (this.props['no-gutter']) {
			const dimension =
				this.props['no-gutter'] === 'all'
					? ''
					: `${this.props['no-gutter']}-`;

			bem += ` grid--no-${dimension}gutter`;
		}

		return this.props.className ? `${bem} ${this.props.className}` : bem;
	}

	render() {
		return (
			<div className={this.getBEM()} style={this.props.style}>
				{this.props.children}
			</div>
		);
	}
}

Grid.propTypes = {
	className: PropTypes.string,
	reverse: PropTypes.string,
	align: PropTypes.string,
	'equal-height': PropTypes.bool,
	'no-gutter': PropTypes.oneOf(['all', 'vertical', 'horizontal']),
};
Grid.defaultProps = {
	children: 'Add some columns inside grid',
};

export default Grid;
